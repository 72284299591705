import { getCookie } from "@component/module/utils";
export const csrf = getCookie("csrftoken");

export const config = (method, body) => ({
  method: method,
  body: JSON.stringify(body),
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-CSRFToken": csrf,
  },
});
