import "./index.scss";

let modal_open = document.querySelectorAll(".modal_open");
let all_modals = document.querySelectorAll(".modal__block");
let modal_close = document.querySelectorAll(".modal_close");

modal_open.forEach((element) => {
  let modal_block = document.querySelector(`.${element.dataset.href}`);

  element.addEventListener("click", function () {
    all_modals.forEach((modal) => {
      modal.classList.remove("active");
    });

    modal_block.classList.add("active");
  });
});

all_modals.forEach((element) => {
  // Додаємо обробник події для кліку на backdrop
  element.addEventListener("click", function (e) {
    const target = e.target;
    // Перевіряємо, чи клікнули саме на backdrop, а не на контент модалки
    if (target === element) {
      element.classList.remove("active");
    }
  });
});

modal_close.forEach((element) => {
  let wrapper = element.closest(".modal__block");
  element.addEventListener("click", function () {
    wrapper.classList.remove("active");
  });
});
