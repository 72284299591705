import Swiper from "swiper";
import { Autoplay, Navigation, Pagination, Scrollbar } from "swiper/modules";
import "swiper/swiper-bundle.css";
import "./index.scss";

Swiper.use([Autoplay, Navigation, Pagination, Scrollbar]);

new Swiper(".home__banner-swiper", {
  slidesPerView: "1",
  autoplay: {
    delay: 6000,
    disableOnInteraction: false,
  },
  loop: "true",
  navigation: {
    prevEl: ".home__banner-button-prev",
    nextEl: ".home__banner-button-next",
  },
});

new Swiper(".projects-swiper", {
  // loop: true,
  spaceBetween: 30,
  slidesPerView: "auto",
  centerInsufficientSlides: true,

  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },

  navigation: {
    nextEl: ".projects-button-next",
    prevEl: ".projects-button-prev",
  },
});

const contactFormNumbers = document.querySelectorAll(".contacts-phone");
contactFormNumbers.forEach((nubmer) =>
  nubmer.addEventListener("input", function (e) {
    let input = e.target;
    let value = input.value.replace(/\D/g, "");
    let formattedValue = "";

    if (value.length > 0) {
      formattedValue += "(" + value.substring(0, 3);
    }
    if (value.length >= 4) {
      formattedValue += ") " + value.substring(3, 6);
    }
    if (value.length >= 7) {
      formattedValue += "-" + value.substring(6, 10);
    }

    input.value = formattedValue;
  })
);

// Select the element we want to observe
const targets = document.querySelectorAll(".invisible-container");

// Create an intersection observer
const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting >= 0.5) {
      // Add 'visible' class when the target is in view
      entry.target.classList.add("visible");
    }
    // Uncomment the following if you want to remove the 'visible' class when the element is out of view
    // else {
    //   entry.target.classList.remove('visible');
    // }
  });
});

// Observe each target element
targets.forEach((target) => {
  observer.observe(target);
});
